import React from "react"
import { Col, Row } from "react-bootstrap"
import { MdKeyboardArrowDown } from "react-icons/md"
import { useTranslation } from "react-i18next"

const I18N_GALLERY = "gallery"

export default function GalleryCategoryHeader({ category }) {
  const { t } = useTranslation()

  return (
    <Row>
      <Col xs={11}>{t(`${I18N_GALLERY}.${category}.title`)}</Col>
      <Col xs={1}>
        <MdKeyboardArrowDown size={20} />
      </Col>
    </Row>
  )
}
