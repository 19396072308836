import React from "react"
import { Link } from "gatsby"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Img from "gatsby-image"

import styles from "./gallery.module.css"

const I18N_GALLERY = "gallery"

export default function GalleryCategory({ preview }) {
  const { t } = useTranslation()

  function parseNameFromSrc(src) {
    let name = src.split("-").join(" ")
    name = name.charAt(0).toUpperCase() + name.slice(1)
    name = name.slice(0, name.lastIndexOf("."))
    return name
  }

  function parseLinkFromSrc(src) {
    return src.slice(0, src.lastIndexOf(".")).toLowerCase()
  }

  return (
    <Card.Body>
      <Row>
        {preview.edges.map(edge => {
          const { fluid } = edge.node.childImageSharp
          const name = parseNameFromSrc(fluid.originalName)
          return (
            <Col key={name} xs={12} sm={6} md={4} lg={4} xl={3}>
              <Card className={styles.cardContainer}>
                <Card.Img
                  as={() => (
                    <div>
                      <Img fluid={fluid} alt={name} />
                      <div className={styles.cardImageTitle}>{name}</div>
                      <Link
                        className={styles.cardText}
                        to={`/galeria/${parseLinkFromSrc(fluid.originalName)}`}
                      >
                        {t(`${I18N_GALLERY}.card.more`)}
                      </Link>
                    </div>
                  )}
                />
              </Card>
            </Col>
          )
        })}
      </Row>
    </Card.Body>
  )
}
