import { useStaticQuery, graphql } from "gatsby"

export default function GalleryLoader({ children }) {
  const preview = useStaticQuery(graphql`
    query preview {
      montaz: allFile(
        filter: { relativeDirectory: { eq: "gallery/preview/montaz" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(fit: FILL, maxHeight: 200, maxWidth: 200) {
                ...GatsbyImageSharpFluid
                src
                originalName
              }
            }
          }
        }
      }
      strecha: allFile(
        filter: { relativeDirectory: { eq: "gallery/preview/strecha" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(fit: FILL, maxHeight: 200, maxWidth: 200) {
                ...GatsbyImageSharpFluid
                src
                originalName
              }
            }
          }
        }
      }
      byty: allFile(
        filter: { relativeDirectory: { eq: "gallery/preview/byty" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(fit: FILL, maxHeight: 200, maxWidth: 200) {
                ...GatsbyImageSharpFluid
                src
                originalName
              }
            }
          }
        }
      }
      holuby: allFile(
        filter: { relativeDirectory: { eq: "gallery/preview/holuby" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(fit: FILL, maxHeight: 200, maxWidth: 200) {
                ...GatsbyImageSharpFluid
                src
                originalName
              }
            }
          }
        }
      }
      firma: allFile(
        filter: { relativeDirectory: { eq: "gallery/preview/firma" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(fit: FILL, maxHeight: 200, maxWidth: 200) {
                ...GatsbyImageSharpFluid
                src
                originalName
              }
            }
          }
        }
      }
      zatekanie: allFile(
        filter: { relativeDirectory: { eq: "gallery/preview/zatekanie" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(fit: FILL, maxHeight: 200, maxWidth: 200) {
                ...GatsbyImageSharpFluid
                src
                originalName
              }
            }
          }
        }
      }
      orezat: allFile(
        filter: { relativeDirectory: { eq: "gallery/preview/orezat" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(fit: FILL, maxHeight: 200, maxWidth: 200) {
                ...GatsbyImageSharpFluid
                src
                originalName
              }
            }
          }
        }
      }
      ostatne: allFile(
        filter: { relativeDirectory: { eq: "gallery/preview/ostatne" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(fit: COVER, maxHeight: 200, maxWidth: 200) {
                ...GatsbyImageSharpFluid
                src
                originalName
              }
            }
          }
        }
      }
    }
  `)

  return children(preview)
}
