import React from "react"
import {
  Accordion,
  AccordionToggle,
  AccordionCollapse,
  Card,
} from "react-bootstrap"

import Layout from "../components/Layout"
import GalleryCategory from "../components/gallery/GalleryCategory"
import GalleryCategoryHeader from "../components/gallery/GalleryCategoryHeader"
import GalleryLoader from "../components/gallery/GalleryLoader"
import styles from "./css/gallery.module.css"

export default function Gallery({ location }) {
  return (
    <Layout>
      <GalleryLoader>
        {preview => (
          <div className={styles.container}>
            <Accordion
              defaultActiveKey={
                (location.state && location.state.defaultActiveKey) || "0"
              }
            >
              <Card>
                <AccordionToggle as={Card.Header} eventKey="0">
                  <GalleryCategoryHeader category={"montaz"} />
                </AccordionToggle>
                <AccordionCollapse eventKey="0">
                  <GalleryCategory preview={preview.montaz} />
                </AccordionCollapse>
              </Card>

              <Card>
                <AccordionToggle as={Card.Header} eventKey="1">
                  <GalleryCategoryHeader category={"strecha"} />
                </AccordionToggle>
                <AccordionCollapse eventKey="1">
                  <GalleryCategory preview={preview.strecha} />
                </AccordionCollapse>
              </Card>

              <Card>
                <AccordionToggle as={Card.Header} eventKey="2">
                  <GalleryCategoryHeader category={"byty"} />
                </AccordionToggle>
                <AccordionCollapse eventKey="2">
                  <GalleryCategory preview={preview.byty} />
                </AccordionCollapse>
              </Card>

              <Card>
                <AccordionToggle as={Card.Header} eventKey="3">
                  <GalleryCategoryHeader category={"holuby"} />
                </AccordionToggle>
                <AccordionCollapse eventKey="3">
                  <GalleryCategory preview={preview.holuby} />
                </AccordionCollapse>
              </Card>

              <Card>
                <AccordionToggle as={Card.Header} eventKey="4">
                  <GalleryCategoryHeader category={"firma"} />
                </AccordionToggle>
                <AccordionCollapse eventKey="4">
                  <GalleryCategory preview={preview.firma} />
                </AccordionCollapse>
              </Card>

              <Card>
                <AccordionToggle as={Card.Header} eventKey="5">
                  <GalleryCategoryHeader category={"zatekanie"} />
                </AccordionToggle>
                <AccordionCollapse eventKey="5">
                  <GalleryCategory preview={preview.zatekanie} />
                </AccordionCollapse>
              </Card>

              <Card>
                <AccordionToggle as={Card.Header} eventKey="6">
                  <GalleryCategoryHeader category={"orezat"} />
                </AccordionToggle>
                <AccordionCollapse eventKey="6">
                  <GalleryCategory preview={preview.orezat} />
                </AccordionCollapse>
              </Card>

              <Card>
                <AccordionToggle as={Card.Header} eventKey="7">
                  <GalleryCategoryHeader category={"ostatne"} />
                </AccordionToggle>
                <AccordionCollapse eventKey="7">
                  <GalleryCategory preview={preview.ostatne} />
                </AccordionCollapse>
              </Card>
            </Accordion>
          </div>
        )}
      </GalleryLoader>
    </Layout>
  )
}
